import { useImmer } from '../../core/utils';
import { toast } from 'vue3-toastify';
import { Operation, OperationStatus } from '@wision/api';
import { useI18n } from 'vue-i18n';

const POLL_INTERVAL = 10000;
const POLL_MAX_COUNT = 30; // Timeout after five minutes

export type OperationsState = {
  devices: { [device: string]: {
    sequence: number;
    name: string;
    operation: Operation,
    count: number;
    error: boolean;
  }},
  timer?: ReturnType<typeof setInterval>;
}

const defaultOperationsState: OperationsState = {
  devices: {},
  timer: undefined,
};

const autoClose = 3000;

export type OperationsApi = ReturnType<typeof useOperationsApi>;

export const useOperationsApi = (
  triggerFn: (deviceId: string, operation: Operation) => Promise<number>,
  pollFn: (deviceId: string, sequence: number, operation: Operation) => Promise<OperationStatus>,
  t: ReturnType<typeof useI18n>['t'],
) => {
  const [state, updateState] = useImmer(defaultOperationsState);

  const deleteDevice = (device: string) => updateState(draft => {
    delete draft.devices[device];
  });

  const stopTimer = () => {
    clearInterval(state.value.timer);
    updateState(draft => {
      draft.timer = undefined;
    });
  };

  const trigger = async (
    device: string,
    name: string,
    operation: Operation,
    handler?: (result: 'error' | 'success') => void
  ) => {
    try {
      const sequence = await triggerFn(device, operation);

      updateState(draft => {
        draft.devices[device] = {
          sequence,
          name,
          operation,
          count: POLL_MAX_COUNT,
          error: false,
        };
      });

      if (!state.value.timer) {
        const timer = setInterval(
          () => poll(handler),
          POLL_INTERVAL
        );

        updateState(draft => {
          draft.timer = timer;
        });
      }

      toast(
        `${t(`unitaction.${operation}`)} ` +
        `${t('unitaction.requestedforunit')} ${name}`, {
          autoClose,
          type: 'info',
          theme: 'dark',
        });

    } catch (err) {
      toast(
        `${t(`unitaction.${operation}`)} ` +
          `${t('unitaction.failedonunit')} ${name}`, {
          autoClose,
          type: 'error',
          theme: 'dark',
        });

      deleteDevice(device);

      if (handler) handler('error');
    }
  };

  const poll = async (handler?: (result: 'error' | 'success') => void) => {
    Object.entries(state.value.devices).forEach(async ([device, entry]) => {
      try {
        const status = await pollFn(device, entry.sequence, entry.operation);

        switch (status) {
        case 'error':
          throw new Error;
        case 'busy': {
          const count = entry.count - 1;

          if (count === 0) {
            toast(
              `${t(`unitaction.${entry.operation}`)} ` +
            `${t('unitaction.failedonunit')} ${entry.name}`, {
                autoClose,
                type: 'error',
                theme: 'dark',
              });

            deleteDevice(device);
            if (handler) handler('error');
          } else
            updateState(draft => {
              draft.devices[device].count -= 1;
            });
          break;
        }
        case 'done':
          toast(
            `${t(`unitaction.${entry.operation}`)} ` +
          `${t('unitaction.succeededonunit')} ${entry.name}`, {
              autoClose,
              type: 'success',
              theme: 'dark',
            });

          deleteDevice(device);
          if (handler) handler('success');
          break;
        }
      } catch (err) {
        toast(
          `${t(`unitaction.${entry.operation}`)} ` +
          `${t('unitaction.failedonunit')} ${entry.name}`, {
            autoClose,
            type: 'error',
            theme: 'dark',
          });

        if (handler) handler('error');
        deleteDevice(device);
      }
    });

    if (Object.keys(state.value.devices).length === 0) stopTimer();
  };

  return { state, trigger };
};

